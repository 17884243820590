import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

function addPulsatingShadowToTransparentShape(shape: HTMLElement) {
    let shadowIntensity = 0.5 // Intenzita pulzovania tieňa
    let shadowOffsetX = 0 // Horizontálny ofset tieňa
    let shadowOffsetY = 10 // Vertikálny ofset tieňa
    let shadowColor = "#8F9900" // Farba tieňa
    let shadowSpread = 0.45 // Rozptýlenie tieňa
    let shadowFocus = 0 // Fokus tieňa

    // Funkcia na aplikáciu pulzujúceho tieňa
    function applyPulsatingShadow() {
        const currentShadow = `${shadowOffsetX}px ${shadowOffsetY}px ${shadowSpread}px ${shadowFocus}px ${shadowColor}`
        shape.style.boxShadow = `0 0 0 1px transparent, ${currentShadow}`

        // Zvýšiť alebo znížiť intenzitu pulzovania
        if (shadowIntensity < 0.9) {
            shadowIntensity += 0.1
        } else {
            shadowIntensity = 0.5
        }

        // Spustiť funkciu znovu po nejakej dobe
        setTimeout(applyPulsatingShadow, 1000) // 1000 ms = 1 sekunda
    }

    // Spustiť pulzujúci tieň
    applyPulsatingShadow()
}

// Použitie funkcie na pridanie pulzujúceho tieňa na priehľadný tvar
const shapeElement = document.getElementById("myShape") // Nahraď ID tvaru
addPulsatingShadowToTransparentShape(shapeElement)
